import React from "react";
import ButtonMaterial from "@material-ui/core/Button";
import Label from "../Label";
import styles from "./styles";

const Button = ({
	title,
	onPress,
	type,
	buttonStyle = null,
	buttonTextStyle = null,
	buttonTextSize = "label",
}) => {
	const classes = styles();
	return (
		<ButtonMaterial
			onClick={() => onPress()}
			className={[
				type === "outline" ? classes.outline : null,
				type === "clean" ? classes.clean : null,
				type === "flat" ? classes.flat : null,
				buttonStyle,
			].join(" ")}
		>
			<Label
				text={title}
				size={buttonTextSize}
				textStyles={[buttonTextStyle]}
			/>
		</ButtonMaterial>
	);
};

export default Button;
