import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "@material-ui/data-grid";
import FloatingButton from "../../components/FloatingButton";
import styles from "./styles";
import Loader from "../../components/Loader";
import Drawer from "../../components/Drawer";
import Modal from "../../components/Modal";
import api from "../../services/api";

const User = () => {
	const history = useHistory();
	const classes = styles();
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalError, setModalError] = useState("");
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState();

	const columns = [
		{ field: "name", headerName: "Nome", flex: 1 },
		{
			field: "email",
			headerName: "Email",
			flex: 1,
		},
		{
			field: "actions",
			headerName: "Ações",
			sortable: false,
			width: 150,
			renderCell: (params) => (
				<>
					<IconButton
						aria-label="Editar"
						onClick={() =>
							history.push("/userform/" + params.row.id)
						}
					>
						<Edit />
					</IconButton>
					<IconButton
						aria-label="Deletar"
						onClick={() => setSelectedUser(params.row.id)}
					>
						<Delete />
					</IconButton>
				</>
			),
		},
	];

	useEffect(() => {
		getUsers();
	}, []);

	useEffect(() => {
		if (selectedUser) {
			setModalVisible(true);
		}
	}, [selectedUser]);

	const getUsers = () => {
		setLoading(true);
		api.post("/users")
			.then((res) => {
				setLoading(false);
				setUsers(res.data);
			})
			.catch(() => {
				setLoading(false);
				setModalError("Não foi possível comunicar com o servidor.");
			});
	};

	const deleteUser = () => {
		if (selectedUser) {
			setLoading(true);
			api.post("/users/delete", { id: selectedUser })
				.then((res) => {
					getUsers();
					setSelectedUser();
					setModalVisible(false);
				})
				.catch(() => {
					setLoading(false);
					setModalError("Não foi possível comunicar com o servidor.");
				});
		}
	};

	return (
		<>
			<Drawer title="Usuários" />
			<Container className={classes.root}>
				<Paper elevation={3} className={classes.card}>
					{users.length > 0 && (
						<DataGrid
							rows={users}
							columns={columns}
							pageSize={10}
							autoHeight
							sortModel={[
								{
									field: "name",
									sort: "asc",
								},
							]}
						/>
					)}
				</Paper>
				<Loader visible={loading} />
				<FloatingButton onPress={() => history.push("/userform")} />
				<Modal
					title="Tem certeza?"
					description="Todos os registros desse usuário serão apagados."
					buttonConfirm="Apagar"
					visible={modalVisible}
					buttonCancelPress={() => setModalVisible(false)}
					buttonConfirmPress={() => deleteUser()}
				/>
				{modalError !== "" && (
					<Modal
						title="Erro"
						description={modalError}
						buttonCancel="Ok"
						visible
						buttonCancelPress={() => setModalError("")}
					/>
				)}
			</Container>
		</>
	);
};

export default User;
