import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./styles";

const Input = ({
	onChangeText,
	value,
	placeholder,
	multline,
	secureTextEntry = false,
	autoCapitalize = "sentences",
	maskType = null,
	required,
}) => {
	const classes = styles();
	const [showPassword, setShowPassword] = useState(false);

	const changeValue = (v) => {
		var x = "";

		switch (maskType) {
			case "phone":
				if (v.length > 14) {
					x = v
						.replace(/\D/g, "")
						.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
					v = !x[2]
						? x[1]
						: "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
				} else {
					x = v
						.replace(/\D/g, "")
						.match(/(\d{0,2})(\d{0,4})(\d{0,4})/);
					v = !x[2]
						? x[1]
						: "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
				}
				break;
			case "cpf":
				x = v
					.replace(/\D/g, "")
					.match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
				v = !x[2]
					? x[1]
					: x[1] +
					  "." +
					  x[2] +
					  (x[3] ? "." + x[3] : "") +
					  (x[4] ? "-" + x[4] : "");
				break;
			default:
				break;
		}

		onChangeText(v);
	};

	return (
		<Box className={classes.root}>
			<TextField
				required={required}
				label={placeholder}
				variant="filled"
				className={classes.input}
				value={value}
				onChange={(text) => changeValue(text.target.value)}
				fullWidth
				autoCapitalize={autoCapitalize}
				multiline={multline}
				type={!showPassword && secureTextEntry ? "password" : "text"}
				inputProps={{
					autoComplete: "new-password",
					form: {
						autoComplete: "off",
					},
				}}
			/>
			{secureTextEntry && (
				<IconButton
					onClick={() => setShowPassword(!showPassword)}
					className={classes.iconEye}
				>
					{showPassword ? (
						<VisibilityOff fontSize="small" />
					) : (
						<Visibility fontSize="small" />
					)}
				</IconButton>
			)}
		</Box>
	);
};

export default Input;
