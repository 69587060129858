import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";

const Label = ({ text, textStyles = null, size }) => {
	const [fontWeight, setFontWeight] = useState("normal");
	const [variant, setVariant] = useState("body1");
	const [component, setComponent] = useState("p");

	useEffect(() => {
		switch (size) {
			case "label":
				setVariant("caption");
				setComponent("small");
				setFontWeight("normal");
				break;
			case "text":
				setVariant("body1");
				setComponent("p");
				setFontWeight("normal");
				break;
			case "h3":
				setVariant("h3");
				setComponent("h3");
				setFontWeight("normal");
				break;
			case "h4":
				setVariant("h4");
				setComponent("h4");
				setFontWeight("normal");
				break;
			case "h2":
				setVariant("h2");
				setComponent("h2");
				setFontWeight("bold");
				break;
			case "h1":
				setVariant("h1");
				setComponent("h1");
				setFontWeight("bold");
				break;
			default:
				setVariant("body1");
				setComponent("p");
				setFontWeight("normal");
				break;
		}
	}, [size]);

	return (
		<Typography
			variant={variant}
			component={component}
			className={textStyles && textStyles.join(" ")}
			style={{ fontWeight: fontWeight }}
		>
			{text}
		</Typography>
	);
};

export default Label;
