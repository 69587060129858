import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
	palette: {
		primary: {
			main: "#F49625",
		},
		lightgrey: {
			main: "#818181",
		},
		morelightgrey: {
			main: "#DDDDDD",
		},
		danger: {
			main: "#F44336",
		},
		info: {
			main: "#2196F3",
		},
		success: {
			main: "#4CAF50",
		},
		warning: {
			main: "#FFC107",
		},
		lightgreyTransparent: {
			main: "rgba(150,150,150,.2)",
		},
	},
});

export default Theme;
