import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
	outline: {
		backgroundColor: "transparent",
		padding: 15,
		borderWidth: 2,
		borderStyle: "solid",
		borderColor: theme.palette.primary.main,
		width: 190,
		margin: 5,
	},
	clean: {
		backgroundColor: "transparent",
		width: 190,
		borderWidth: 0,
		padding: 5,
		margin: 5,
		cursor: "pointer",
	},
	flat: {
		backgroundColor: theme.palette.primary.main,
		padding: 15,
		width: 190,
		margin: 5,
		boxShadow: "1px 1px 4px 0px rgba(0,0,0,.4)",
	},
	textOutline: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		textAlign: "center",
	},
	textClean: {
		color: theme.palette.primary.main,
		textTransform: "uppercase",
		textAlign: "center",
	},
	textFlat: {
		color: "white",
		textTransform: "uppercase",
		textAlign: "center",
	},
}));

export default styles;
