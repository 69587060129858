import React, { useState, useContext } from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Logo from "../../images/logo.png";
import styles from "./styles";
import Input from "../../components/Input";
import AuthContext from "../../contexts/auth";
import Modal from "../../components/Modal";
import api from "../../services/api";

const Login = () => {
	const classes = styles();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [modal, setModal] = useState("");
	const { signIn } = useContext(AuthContext);

	const login = () => {
		signIn(email, password);
	};

	const forgotPassword = () => {
		if (email !== "") {
			setLoading(true);
			api.post("/users/forgotPassword", { email })
				.then(() => {
					setLoading(false);
					setModal(
						"Foi enviado um link para troca de senha no seu email."
					);
				})
				.catch(() => {
					setLoading(false);
					setModal("O usuário não foi encontrado.");
				});
		} else {
			setModal(
				"Preencha o campo email antes de solicitar a troca de senha."
			);
		}
	};

	return (
		<Container className={classes.root}>
			<div className={classes.imgOverlay} />
			<Paper elevation={3} className={classes.card}>
				<img src={Logo} alt="" className={classes.img} />
				<form
					className={classes.formRoot}
					noValidate
					autoComplete="off"
				>
					<Input
						onChangeText={(value) => setEmail(value)}
						value={email}
						placeholder="Email"
					/>
					<Input
						secureTextEntry
						onChangeText={(value) => setPassword(value)}
						value={password}
						placeholder="Senha"
						autoCapitalize="none"
					/>
					<Button
						title="Entrar"
						onPress={() => login()}
						type="flat"
						buttonStyle={classes.buttonStyle}
					/>
					<Button
						title="Esqueci a senha"
						onPress={() => forgotPassword()}
						type="clean"
						buttonStyle={classes.buttonStyle}
					/>
				</form>
			</Paper>
			<Loader visible={loading} />
			{modal !== "" && (
				<Modal
					title="Atenção"
					description={modal}
					buttonCancel="Ok"
					visible
					buttonCancelPress={() => setModal("")}
				/>
			)}
		</Container>
	);
};

export default Login;
