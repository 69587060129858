import api from "./api";

export const signIn = (email, password) => {
	return new Promise((resolve, reject) => {
		api.post("/users/login", { email, password })
			.then((res) => {
				resolve({
					token: res.data.token,
					user: {
						name: res.data.name,
						email: res.data.email,
						firstAccess: res.data.first_access,
					},
				});
			})
			.catch((err) => {
				reject(err);
			});
	});
};
