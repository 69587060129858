import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
	root: {
		position: "relative",
		width: "100%",
	},
	input: {
		height: 60,
	},
	iconEye: {
		position: "absolute",
		right: 0,
		bottom: 10,
	},
}));

export default styles;
