import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
	container: {
		position: "fixed",
		top: 0,
		left: 0,
		backgroundColor: theme.palette.primary.main,
		height: 60,
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		flexDirection: "row",
		width: "100%",
	},
	title: {
		color: "white",
		textTransform: "capitalize",
	},
	leftComponent: {
		flexDirection: "row",
		alignItems: "center",
	},
	leftButtonComponent: {
		color: "white",
	},
}));

export default styles;
