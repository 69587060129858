import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/auth";
import Routes from "./routes";
import Theme from "./Theme";

const App = () => {
	return (
		<ThemeProvider theme={Theme}>
			<Router>
				<AuthProvider>
					<Routes />
				</AuthProvider>
			</Router>
		</ThemeProvider>
	);
};

export default App;
