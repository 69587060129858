import React, { createContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Loader from "../components/Loader";
import * as auth from "../services/auth";
import api from "../services/api";
import Modal from "../components/Modal";

const AuthContext = createContext({ signed: true, token: "" });

const storage = sessionStorage;

const useStyle = makeStyles((theme) => ({
	container: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const [loading, setLoading] = useState(true);
	const [modalMessage, setModalMessage] = useState("");

	const classes = useStyle();

	useEffect(() => {
		async function loadStorageData() {
			setLoading(true);
			const storagedUser = storage.getItem("@Auth:user");
			const storagedToken = storage.getItem("@Auth:token");

			if (storagedUser && storagedToken) {
				api.defaults.headers.common["Authorization"] = storagedToken;
				setUser(JSON.parse(storagedUser));
				setToken(storagedToken);
			}
			setLoading(false);
		}

		loadStorageData();
	}, []);

	const signIn = (email, password) => {
		setLoading(true);
		auth.signIn(email, password)
			.then((response) => {
				setLoading(false);
				api.defaults.headers.common["Authorization"] = response.token;
				setUser(response.user);
				setToken(response.token);
				storage.setItem("@Auth:user", JSON.stringify(response.user));
				storage.setItem("@Auth:token", response.token);
			})
			.catch((err) => {
				setLoading(false);
				if (err?.response?.data?.message) {
					setModalMessage(err?.response?.data?.message);
				} else {
					setModalMessage(
						"Erro desconhecido, contacte o administrador."
					);
				}
			});
	};

	const signOut = async () => {
		storage.clear();
		setUser(null);
	};

	const updateUser = async (userProp) => {
		storage.setItem("@Auth:user", JSON.stringify(userProp));
		setUser(userProp);
	};

	if (loading) {
		return (
			<div className={classes.container}>
				<Loader visible />
			</div>
		);
	} else {
		return (
			<AuthContext.Provider
				value={{
					signed: !!user,
					user,
					signIn,
					signOut,
					token,
					updateUser,
				}}
			>
				{children}
				{modalMessage !== "" && (
					<Modal
						title="Erro"
						description={modalMessage}
						buttonCancel="Ok"
						visible
						buttonCancelPress={() => setModalMessage("")}
					/>
				)}
			</AuthContext.Provider>
		);
	}
};

export default AuthContext;
