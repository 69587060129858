import React from "react";
import Box from "@material-ui/core/Box";
import Label from "../Label";
import styles from "./styles";
import Button from "../Button";

const Modal = ({
	visible = false,
	title,
	description,
	buttonCancel = "Cancelar",
	buttonConfirm = "Confirmar",
	buttonCancelPress = null,
	buttonConfirmPress = null,
}) => {
	const classes = styles();
	return visible ? (
		<Box className={classes.modalContainer}>
			<Box className={classes.modal}>
				<Label text={title} size="h4" textStyles={[classes.title]} />
				<Label
					text={description}
					size="text"
					textStyles={[classes.title]}
				/>
				<Box className={classes.buttonFooter}>
					{buttonCancelPress && (
						<Button
							type="clean"
							title={buttonCancel}
							onPress={() => buttonCancelPress()}
							buttonStyle={classes.buttonStyle}
							buttonTextStyle={classes.textStyleCancel}
						/>
					)}
					{buttonConfirmPress && (
						<Button
							type="clean"
							title={buttonConfirm}
							buttonStyle={classes.buttonStyle}
							buttonTextStyle={classes.textStyleConfirm}
							onPress={() => buttonConfirmPress()}
						/>
					)}
				</Box>
			</Box>
		</Box>
	) : (
		<></>
	);
};

export default Modal;
