import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import styles from "./styles";

const InputSelect = ({
	onChangeText,
	value,
	placeholder,
	items = [],
	required,
}) => {
	const classes = styles();

	return (
		<FormControl className={classes.root}>
			<InputLabel className={classes.label}>
				{placeholder}
				{required && "*"}
			</InputLabel>
			<Select
				variant="filled"
				value={value}
				onChange={(value) => onChangeText(value.target.value)}
				autoWidth
				fullWidth
			>
				{items.map((item, index) => (
					<MenuItem key={index.toString()} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default InputSelect;
