import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { Menu, ArrowBack } from "@material-ui/icons";
import Label from "../Label";
import styles from "./styles";

const Header = ({ title, onPress, backButton }) => {
	const history = useHistory();
	const classes = styles();
	return (
		<Box className={classes.container}>
			{backButton ? (
				<IconButton onClick={() => history.goBack()}>
					<ArrowBack fontSize="large" htmlColor="white" />
				</IconButton>
			) : (
				<IconButton onClick={onPress}>
					<Menu fontSize="large" htmlColor="white" />
				</IconButton>
			)}

			<Label text={title} textStyles={[classes.title]} />
		</Box>
	);
};

export default Header;
