import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useHistory, useParams } from "react-router";
import styles from "./styles";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Drawer from "../../components/Drawer";
import Label from "../../components/Label";
import Modal from "../../components/Modal";
import api from "../../services/api";

const OwnerForm = () => {
	const history = useHistory();
	const classes = styles();
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [doc, setDoc] = useState("");
	const [email, setEmail] = useState("");
	const [modalError, setModalError] = useState("");
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getOwner();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const save = () => {
		validateForm()
			.then(() => {
				setLoading(true);
				if (id) {
					api.post("/owners/save", {
						id,
						name,
						phone,
						doc,
						email,
					})
						.then((res) => {
							setLoading(false);
							history.replace("/owner");
						})
						.catch(() => {
							setLoading(false);
							setModalError(
								"Não foi possível comunicar com o servidor."
							);
						});
				} else {
					api.post("/owners/create", {
						name,
						phone,
						doc,
						email,
					})
						.then((res) => {
							setLoading(false);
							history.replace("/owner");
						})
						.catch((err) => {
							setLoading(false);
							if (err?.response?.data?.message) {
								setModalError(err?.response?.data?.message);
							} else {
								setModalError(
									"Não foi possível comunicar com o servidor."
								);
							}
						});
				}
			})
			.catch(() => {
				setModalError("Preencha todos os campos obrigatórios.");
			});
	};

	const getOwner = () => {
		setLoading(true);
		api.post("/owners/edit", { id })
			.then((res) => {
				setName(res.data?.name || "");
				setPhone(res.data?.phone || "");
				setDoc(res.data?.doc || "");
				setEmail(res.data?.email || "");
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				setModalError("Não foi possível comunicar com o servidor.");
			});
	};

	const validateForm = () => {
		return new Promise((resolve, reject) => {
			let errorCounter = 0;

			if (name === "") {
				errorCounter++;
			}

			if (phone === "") {
				errorCounter++;
			}

			if (doc === "") {
				errorCounter++;
			}

			if (email === "") {
				errorCounter++;
			}

			if (errorCounter > 0) {
				reject();
			} else {
				resolve();
			}
		});
	};

	return (
		<>
			<Drawer title="Proprietários" backButton />
			<Container className={classes.root}>
				<Paper elevation={3} className={classes.card}>
					<Label size="h4" text="Cadastre um novo proprietário" />
					<Grid container spacing={3} className={classes.grid}>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setName(value)}
								value={name}
								placeholder="Nome"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setEmail(value)}
								value={email}
								placeholder="Email"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setPhone(value)}
								value={phone}
								placeholder="Telefone"
								maskType="phone"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setDoc(value)}
								value={doc}
								placeholder="CPF"
								maskType="cpf"
							/>
						</Grid>
					</Grid>
					<Button
						title="Salvar"
						onPress={() => save()}
						type="flat"
						buttonStyle={classes.buttonStyle}
					/>
				</Paper>
				<Loader visible={loading} />
				{modalError !== "" && (
					<Modal
						title="Erro"
						description={modalError}
						buttonCancel="Ok"
						visible
						buttonCancelPress={() => setModalError("")}
					/>
				)}
			</Container>
		</>
	);
};

export default OwnerForm;
