import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
	floating: {
		position: "fixed",
		bottom: 30,
		right: 30,
		borderRadius: 50,
		width: 60,
		height: 60,
	},
}));

export default styles;
