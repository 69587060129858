import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { useHistory, useParams } from "react-router";
import styles from "./styles";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Drawer from "../../components/Drawer";
import Label from "../../components/Label";
import Modal from "../../components/Modal";
import api from "../../services/api";

const UserForm = () => {
	const history = useHistory();
	const classes = styles();
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [modalError, setModalError] = useState("");
	const { id } = useParams();

	useEffect(() => {
		if (id) {
			getUser();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const save = () => {
		validateForm()
			.then(() => {
				setLoading(true);
				if (id) {
					api.post("/users/save", { id, email, name })
						.then((res) => {
							setLoading(false);
							history.replace("/user");
						})
						.catch(() => {
							setLoading(false);
							setModalError(
								"Não foi possível comunicar com o servidor."
							);
						});
				} else {
					api.post("/users/create", { email, name })
						.then((res) => {
							setLoading(false);
							history.replace("/user");
						})
						.catch((err) => {
							setLoading(false);
							if (err?.response?.data?.message) {
								setModalError(err?.response?.data?.message);
							} else {
								setModalError(
									"Não foi possível comunicar com o servidor."
								);
							}
						});
				}
			})
			.catch(() => {
				setModalError("Preencha os campos obrigatórios.");
			});
	};

	const getUser = () => {
		setLoading(true);
		api.post("/users/edit", { id })
			.then((res) => {
				setName(res.data?.name || "");
				setEmail(res.data?.email || "");
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				setModalError("Não foi possível comunicar com o servidor.");
			});
	};

	const validateForm = () => {
		return new Promise((resolve, reject) => {
			let errorCounter = 0;

			if (name === "") {
				errorCounter++;
			}

			if (email === "") {
				errorCounter++;
			}

			if (errorCounter > 0) {
				reject();
			} else {
				resolve();
			}
		});
	};

	return (
		<>
			<Drawer title="Usuários" backButton />
			<Container className={classes.root}>
				<Paper elevation={3} className={classes.card}>
					<Label size="h4" text="Cadastre um novo usuário" />
					<Grid container spacing={3} className={classes.grid}>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								onChangeText={(value) => setName(value)}
								value={name}
								placeholder="Nome"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								onChangeText={(value) => setEmail(value)}
								value={email}
								placeholder="Email"
							/>
						</Grid>
					</Grid>
					<Button
						title="Salvar"
						onPress={() => save()}
						type="flat"
						buttonStyle={classes.buttonStyle}
					/>
				</Paper>
				<Loader visible={loading} />
				{modalError !== "" && (
					<Modal
						title="Erro"
						description={modalError}
						buttonCancel="Ok"
						visible
						buttonCancelPress={() => setModalError("")}
					/>
				)}
			</Container>
		</>
	);
};

export default UserForm;
