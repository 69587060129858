import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
	list: {
		width: 250,
	},
	fullList: {
		width: "auto",
	},
	box: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: 20,
	},
	img: {
		width: 80,
	},
});

export default styles;
