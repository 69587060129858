import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
	container: {
		display: "flex",
		position: "fixed",
		top: 0,
		left: 0,
		backgroundColor: "rgba(0,0,0,.4)",
		zIndex: 999999999,
		width: "100%",
		height: "100vh",
		justifyContent: "center",
		alignItems: "center",
	},
	modal: {
		display: "flex",
		width: 120,
		height: 120,
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "white",
		borderRadius: 10,
	},
}));

export default styles;
