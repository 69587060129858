import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import styles from "./styles";

const Loader = ({ visible }) => {
	const classes = styles();

	return visible ? (
		<Box className={classes.container}>
			<Box className={classes.modal}>
				<CircularProgress />
			</Box>
		</Box>
	) : null;
};

export default Loader;
