import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Delete } from "@material-ui/icons";
import ImageUploading from "react-images-uploading";
import styles from "./styles";
import Loader from "../../components/Loader";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import Button from "../../components/Button";
import Drawer from "../../components/Drawer";
import Label from "../../components/Label";
import Modal from "../../components/Modal";
import api from "../../services/api";
import { Box, IconButton } from "@material-ui/core";
import axios from "axios";

const ApartmentForm = () => {
	const history = useHistory();
	const classes = styles();
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState("");
	const [address, setAddress] = useState("");
	const [description, setDescription] = useState("");
	const [owner, setOwner] = useState("");
	const [deviceId, setDeviceId] = useState("");
	const [owners, setOwners] = useState([]);
	const [obs, setObs] = useState("");
	const [modalError, setModalError] = useState("");
	const [images, setImages] = useState([]);
	const { id } = useParams();

	const maxNumber = 4;

	const onChange = (imageList, addUpdateIndex) => {
		setImages(imageList);
	};

	useEffect(() => {
		getOwners();
	}, []);

	useEffect(() => {
		if (owners.length > 0) {
			if (id) {
				getApartment();
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [owners]);

	const save = () => {
		validateForm()
			.then(() => {
				setLoading(true);

				const imageArr = images.map((i) => i.data_url);

				if (id) {
					api.post("/apartments/save", {
						id,
						name,
						description,
						obs,
						address,
						owner,
						deviceId,
						images: imageArr,
					})
						.then((res) => {
							setLoading(false);
							history.replace("/apartment");
						})
						.catch(() => {
							setLoading(false);
							setModalError(
								"Não foi possível comunicar com o servidor."
							);
						});
				} else {
					api.post("/apartments/create", {
						name,
						description,
						obs,
						address,
						owner,
						deviceId,
					})
						.then((res) => {
							setLoading(false);
							history.replace("/apartment");
						})
						.catch((err) => {
							setLoading(false);
							if (err?.response?.data?.message) {
								setModalError(err?.response?.data?.message);
							} else {
								setModalError(
									"Não foi possível comunicar com o servidor."
								);
							}
						});
				}
			})
			.catch(() => {
				setModalError("Preencha todos os campos obrigatórios.");
			});
	};

	const getApartment = async () => {
		setLoading(true);
		api.post("/apartments/edit", { id })
			.then(async (res) => {
				setName(res.data.name || "");
				setAddress(res.data.address || "");
				setDescription(res.data.description || "");
				setObs(res.data.obs || "");
				setOwner(res.data.owner.id || "");
				setDeviceId(res.data.deviceId || "");

				// const imageArr = res.data.images.map((i) => ({
				// 	data_url: `https://storage.googleapis.com/uplifestyle-api/${i.image}`,
				// }));

				const imageArr = await Promise.all(
					res.data.images.map(async (i) => {
						return {
							data_url: await getBase64(
								`https://storage.googleapis.com/uplifestyle-api/${i.image}`
							),
						};
					})
				);

				setImages(imageArr);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
				setModalError("Não foi possível comunicar com o servidor.");
			});
	};

	const getOwners = () => {
		setLoading(true);
		api.post("/owners")
			.then((res) => {
				setLoading(false);

				const ownersArr = res.data.map((item) => {
					return { label: item.name, value: item.id };
				});
				setOwners(ownersArr);
			})
			.catch(() => {
				setLoading(false);
				setModalError("Não foi possível comunicar com o servidor.");
			});
	};

	const validateForm = () => {
		return new Promise((resolve, reject) => {
			let errorCounter = 0;

			if (name === "") {
				errorCounter++;
			}

			if (address === "") {
				errorCounter++;
			}

			if (description === "") {
				errorCounter++;
			}

			if (owner === "") {
				errorCounter++;
			}

			if (deviceId === "") {
				errorCounter++;
			}

			if (errorCounter > 0) {
				reject();
			} else {
				resolve();
			}
		});
	};

	async function getBase64(url) {
		const extension = url.split(".")[url.split(".").length - 1];
		const response = await axios.get(url, {
			responseType: "arraybuffer",
			contentType: "application/x-www-form-urlencoded",
			dataType: "jsonp",
		});
		return `data:image/${extension};base64,${Buffer.from(
			response.data,
			"binary"
		).toString("base64")}`;
	}

	return (
		<>
			<Drawer title="Apartamentos" backButton />
			<Container className={classes.root}>
				<Paper elevation={3} className={classes.card}>
					<Label size="h4" text="Cadastre um novo apartamento" />
					<Grid container spacing={3} className={classes.grid}>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setName(value)}
								value={name}
								placeholder="Nome"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setDescription(value)}
								value={description}
								placeholder="Descrição"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setAddress(value)}
								value={address}
								placeholder="Endereço"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<Input
								required
								onChangeText={(value) => setDeviceId(value)}
								value={deviceId}
								placeholder="ID da Fechadura"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4}>
							<InputSelect
								required
								onChangeText={(value) => setOwner(value)}
								value={owner}
								placeholder="Proprietário"
								items={owners}
							/>
						</Grid>
						<Grid item xs={12}>
							<Input
								onChangeText={(value) => setObs(value)}
								value={obs}
								placeholder="Observações"
								multline
							/>
						</Grid>
						<Grid item xs={12}>
							<ImageUploading
								multiple
								value={images}
								onChange={onChange}
								maxNumber={maxNumber}
								dataURLKey="data_url"
							>
								{({
									imageList,
									onImageUpload,
									onImageRemoveAll,
									onImageUpdate,
									onImageRemove,
									isDragging,
									dragProps,
								}) => (
									<div className="upload__image-wrapper">
										<Button
											type={
												isDragging ? "outline" : "flat"
											}
											buttonStyle={classes.buttonUpload}
											onPress={onImageUpload}
											title="Inserir imagens"
											{...dragProps}
										/>
										{imageList.length > 0 && (
											<Button
												type="clean"
												buttonStyle={
													classes.buttonUpload
												}
												onPress={onImageRemoveAll}
												title="Remover tudo"
											/>
										)}

										<Box
											sx={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "center",
												flexDirection: "row",
											}}
										>
											{imageList.map((image, index) => (
												<div
													key={index}
													className="image-item"
													style={{
														marginRight: 10,
														marginLeft: 5,
													}}
												>
													<img
														src={image["data_url"]}
														alt=""
														height="100"
													/>
													<div className="image-item__btn-wrapper">
														<IconButton
															aria-label="delete"
															onClick={() =>
																onImageRemove(
																	index
																)
															}
														>
															<Delete />
														</IconButton>
													</div>
												</div>
											))}
										</Box>
									</div>
								)}
							</ImageUploading>
						</Grid>
					</Grid>
					<Button
						title="Salvar"
						onPress={() => save()}
						type="flat"
						buttonStyle={classes.buttonStyle}
					/>
				</Paper>
				<Loader visible={loading} />
				{modalError !== "" && (
					<Modal
						title="Erro"
						description={modalError}
						buttonCancel="Ok"
						visible
						buttonCancelPress={() => setModalError("")}
					/>
				)}
			</Container>
		</>
	);
};

export default ApartmentForm;
