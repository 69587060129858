import React from "react";
import ButtonBase from "@material-ui/core/Button";
import { Add } from "@material-ui/icons";
import styles from "./styles";

const FloatingButton = ({ onPress = null }) => {
	const classes = styles();
	return (
		<ButtonBase
			onClick={onPress}
			className={classes.floating}
			variant="contained"
			color="primary"
		>
			<Add fontSize="large" htmlColor="white" />
		</ButtonBase>
	);
};

export default FloatingButton;
