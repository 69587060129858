import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import { Delete, Edit } from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "@material-ui/data-grid";
import FloatingButton from "../../components/FloatingButton";
import styles from "./styles";
import Loader from "../../components/Loader";
import Drawer from "../../components/Drawer";
import Modal from "../../components/Modal";
import api from "../../services/api";

const Apartment = () => {
	const history = useHistory();
	const classes = styles();
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalError, setModalError] = useState("");
	const [apartments, setApartments] = useState([]);
	const [selectedApartment, setSelectedApartment] = useState();

	const columns = [
		{ field: "name", headerName: "Nome", flex: 1 },
		{
			field: "owner",
			headerName: "Proprietário",
			flex: 1,
		},
		{
			field: "description",
			headerName: "Descrição",
			flex: 1,
		},
		{
			field: "actions",
			headerName: "Ações",
			sortable: false,
			width: 150,
			renderCell: (params) => (
				<>
					<IconButton
						aria-label="Editar"
						onClick={() =>
							history.push("/apartmentform/" + params.row.id)
						}
					>
						<Edit />
					</IconButton>
					<IconButton
						aria-label="Deletar"
						onClick={() => setSelectedApartment(params.row.id)}
					>
						<Delete />
					</IconButton>
				</>
			),
		},
	];

	useEffect(() => {
		getApartments();
	}, []);

	useEffect(() => {
		if (selectedApartment) {
			setModalVisible(true);
		}
	}, [selectedApartment]);

	const getApartments = () => {
		setLoading(true);
		api.post("/apartments")
			.then((res) => {
				setLoading(false);
				let apartmentArr = res.data.map(
					({ id, name, owner, description }) => {
						return { id, name, description, owner: owner?.name };
					}
				);

				setApartments(apartmentArr);
			})
			.catch(() => {
				setLoading(false);
				setModalError("Não foi possível comunicar com o servidor.");
			});
	};

	const deleteApartment = () => {
		if (selectedApartment) {
			setLoading(true);
			api.post("/apartments/delete", { id: selectedApartment })
				.then((res) => {
					getApartments();
					setSelectedApartment();
					setModalVisible(false);
				})
				.catch(() => {
					setLoading(false);
					setModalError("Não foi possível comunicar com o servidor.");
				});
		}
	};

	return (
		<>
			<Drawer title="Apartamentos" />
			<Container className={classes.root}>
				<Paper elevation={3} className={classes.card}>
					{apartments.length > 0 && (
						<DataGrid
							rows={apartments}
							columns={columns}
							pageSize={10}
							autoHeight
							sortModel={[
								{
									field: "name",
									sort: "asc",
								},
							]}
						/>
					)}
				</Paper>
				<Loader visible={loading} />
				<FloatingButton
					onPress={() => history.push("/apartmentform")}
				/>
				<Modal
					title="Tem certeza?"
					description="Todos os registros desse apartamento serão apagados."
					buttonConfirm="Apagar"
					visible={modalVisible}
					buttonCancelPress={() => setModalVisible(false)}
					buttonConfirmPress={() => deleteApartment()}
				/>
				{modalError !== "" && (
					<Modal
						title="Erro"
						description={modalError}
						buttonCancel="Ok"
						visible
						buttonCancelPress={() => setModalError("")}
					/>
				)}
			</Container>
		</>
	);
};

export default Apartment;
