import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		alignItems: "center",
		minHeight: "100vh",
		padding: 20,
		paddingTop: 60,
	},
	formRoot: {
		"& > *": {
			margin: theme.spacing(1),
		},
		width: "100%",
		maxWidth: "100%",
		flexDirection: "column",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	img: {
		width: 80,
		marginBottom: 20,
	},
	card: {
		width: "100%",
		padding: 20,
		position: "relative",
		height: 650,
		maxWidth: 1200,
	},
	buttonStyle: {
		alignSelf: "center",
	},
	grid: {
		marginTop: 20,
		marginBottom: 20,
	},
	buttonUpload: {
		padding: 10,
		width: "auto",
	},
	imageContainer: {
		marginRight: 10,
		marginLeft: 5,
	},
}));

export default styles;
