import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import DrawerBase from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
	Person,
	Apartment,
	ExitToApp,
	SupervisorAccount,
} from "@material-ui/icons";
import AuthContext from "../../contexts/auth";
import Logo from "../../images/logo.png";
import styles from "./styles";
import Header from "../Header";

const Drawer = ({ title, backButton }) => {
	const history = useHistory();
	const anchor = "left";
	const classes = styles();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const { signOut } = useContext(AuthContext);

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}
		setDrawerOpen(open);
	};

	const list = (anchor) => (
		<div
			className={classes.list}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				<ListItem button onClick={() => history.replace("user")}>
					<ListItemIcon>
						<SupervisorAccount />
					</ListItemIcon>
					<ListItemText primary="Usuários" />
				</ListItem>
				<ListItem button onClick={() => history.replace("owner")}>
					<ListItemIcon>
						<Person />
					</ListItemIcon>
					<ListItemText primary="Proprietários" />
				</ListItem>
				<ListItem button onClick={() => history.replace("apartment")}>
					<ListItemIcon>
						<Apartment />
					</ListItemIcon>
					<ListItemText primary="Apartamentos" />
				</ListItem>
				<ListItem button onClick={signOut}>
					<ListItemIcon>
						<ExitToApp />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</ListItem>
			</List>
		</div>
	);

	return (
		<React.Fragment>
			<Header
				title={title}
				onPress={toggleDrawer(anchor, true)}
				backButton={backButton}
			/>
			<DrawerBase
				anchor={anchor}
				open={drawerOpen}
				onClose={toggleDrawer(anchor, false)}
			>
				<Box className={classes.box}>
					<img src={Logo} alt="" className={classes.img} />
				</Box>
				{list(anchor)}
			</DrawerBase>
		</React.Fragment>
	);
};

export default Drawer;
