import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Button from "../../components/Button";
import Logo from "../../images/logo.png";
import styles from "./styles";
import Input from "../../components/Input";
import Label from "../../components/Label";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import api from "../../services/api";

const ForgotPassword = () => {
	const history = useHistory();
	const classes = styles();
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [passwordChanged, setPasswordChanged] = useState(false);
	const [modal, setModal] = useState("");

	const changePassword = () => {
		const token = history?.location?.search.replace("?", "");

		if (password.length < 8) {
			setModal("A senha precisa ter no mínimo 8 caracteres.");
		} else {
			setLoading(true);
			api.post("/users/changePassword", { token, password })
				.then((res) => {
					setLoading(false);
					setPasswordChanged(true);
					setModal(
						"Senha alterada, entre no sistema com a sua nova senha."
					);
				})
				.catch((err) => {
					setLoading(false);
					setModal(
						err?.response?.data?.message || "Ocorreu um erro."
					);
				});
		}
	};

	return (
		<Container className={classes.root}>
			<div className={classes.imgOverlay} />
			<Paper elevation={3} className={classes.card}>
				<img src={Logo} alt="" className={classes.img} />
				<Label text="Digite a sua nova senha" size="text" />
				<form
					className={classes.formRoot}
					noValidate
					autoComplete="off"
				>
					<Input
						secureTextEntry
						onChangeText={(value) => setPassword(value)}
						value={password}
						placeholder="Senha"
						autoCapitalize="none"
					/>
					<Button
						title="Alterar senha"
						onPress={() => changePassword()}
						type="flat"
						buttonStyle={classes.buttonStyle}
					/>
				</form>
			</Paper>
			<Loader visible={loading} />
			{modal !== "" && (
				<Modal
					title="Atenção"
					description={modal}
					buttonCancel="Ok"
					visible
					buttonCancelPress={() =>
						passwordChanged
							? history.replace("/login")
							: setModal("")
					}
				/>
			)}
		</Container>
	);
};

export default ForgotPassword;
