import React, { useContext, useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import Login from "../screens/Login";
import ChangePassword from "../screens/ChangePassword";
import ForgotPassword from "../screens/ForgotPassword";
import AuthContext from "../contexts/auth";
import Owner from "../screens/Owner";
import OwnerForm from "../screens/OwnerForm";
import User from "../screens/User";
import UserForm from "../screens/UserForm";
import Apartment from "../screens/Apartment";
import ApartmentForm from "../screens/ApartmentForm";

const Routes = () => {
	let history = useHistory();
	let location = useLocation();
	const { signed, user } = useContext(AuthContext);

	useEffect(() => {
		if (!signed) {
			if (location.pathname !== "/forgotpassword") {
				history.replace("login");
			}
		} else if (user.firstAccess) {
			history.replace("changepassword");
		} else if (
			location.pathname === "/login" ||
			location.pathname === "/"
		) {
			history.replace("owner");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signed]);
	return (
		<Switch>
			<Route path="/login">
				<Login />
			</Route>
			<Route path="/changepassword">
				<ChangePassword />
			</Route>
			<Route path="/forgotpassword">
				<ForgotPassword />
			</Route>
			<Route path="/user">
				<User />
			</Route>
			<Route path="/userform/:id?">
				<UserForm />
			</Route>
			<Route path="/owner">
				<Owner />
			</Route>
			<Route path="/ownerform/:id?">
				<OwnerForm />
			</Route>
			<Route path="/apartment">
				<Apartment />
			</Route>
			<Route path="/apartmentform/:id?">
				<ApartmentForm />
			</Route>
		</Switch>
	);
};

export default Routes;
